<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col
          class="d-flex align-items-center"
          cols="12"
        >
          <h2 class="content-header-title float-left mb-0 d-flex align-items-center">
            <b-button
              variant="gradient-danger"
              size="sm"
              class="btn-icon rounded-circle mr-1"
              @click="routeback"
            >
              <feather-icon
                size="20"
                icon="ArrowLeftIcon"
              />
            </b-button>
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  computed: {
    breadcrumb() {
      return this.$route.meta.breadcrumb
    },
  },
  methods: {
    routeback() {
      this.$router.go(-1)
    },
  },
}
</script>
