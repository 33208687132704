<template>
  <b-nav-item-dropdown
    ref="appDropdown"
    class="dropdown-notification"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <b-avatar
        class="mr-50"
        size="36"
        variant="light-secondary"
      >
        <mdi-icon
          icon="AlphaZ"
          size="28"
        />
      </b-avatar>
    </template>
    <div class="app-scroll-area">
      <b-row>
        <b-col
          v-for="item in navigationData"
          :key="item.title"
          class="app-redirect-card rounded p-1"
          cols="4"
          @click="goRoute(item)"
        >
          <span class="d-flex gap-7 align-items-center flex-column justify-content-center">
            <b-avatar
              variant="secondary"
              size="40"
              circle-rounded
            >
              <feather-icon
                v-if="item.icon"
                :icon="item.icon"
                size="25"
              />
              <mdi-icon
                v-else-if="item.mdiIcon"
                :icon="item.mdiIcon"
                size="25"
              />
              <mdil-icon
                v-else-if="item.mdilIcon"
                :icon="item.mdilIcon"
                size="25"
              />
            </b-avatar>
            <span class="font-weight-bold text-center">{{ item.title }}</span>
          </span>
        </b-col>
      </b-row>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import appDropdownNavigation from './appDropdownNavigation'

export default {
  data() {
    return {
      navigationData: appDropdownNavigation,
    }
  },
  methods: {
    goRoute(item) {
      item?.route && this.$route.name !== item.route && this.$router.push({ name: item.route })
      this.$refs.appDropdown.hide()
    },
  },
}
</script>

<style>
.app-redirect-card {
  cursor: pointer;
}
.app-redirect-card:hover {
  background-color: #f8f8f8;
}

.app-scroll-area {
  max-height: 400px;
  overflow: auto;
  padding: 0rem 1.5rem;
}
</style>
