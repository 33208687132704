export default {
  pages: {
    key: "title",
    data: [
      {
        title: "Kurumsal Müşteriler",
        route: { name: "companies" },
        icon: "BriefcaseIcon",
        isBookmarked: false,
      },
    ],
  },
};
