export default [
  {
    title: 'İBYS',
    icon: 'HardDriveIcon',
    variant: 'danger',
  },
  {
    title: 'İK',
    icon: 'UsersIcon',
    variant: 'danger',
  },
  {
    title: 'FİLO YÖNETİM',
    mdiIcon: 'CarMultiple',
    route: 'riskomer-filo',
  },
]
