<template>
  <div class="navbar-container d-flex content align-items-center p-50">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="nav bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-toggler />
      <search-bar />
      <a class="nav-link nav-link-search text-secondary text-darken-2">
        <feather-icon icon="MessageCircleIcon" size="21" />
      </a>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto mr-50 gap-5">
      <app-dropdown />
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div v-if="user" class="d-sm-flex d-none user-nav">
            <p style="text-transform: capitalize" class="user-name font-weight-bolder mb-0">
              {{ user.fullname }}
            </p>
            <span v-if="user && user.role && user.role.value" style="text-transform: capitalize" class="user-status">{{ user.role.value }}</span>
          </div>
          <b-avatar size="37" variant="secondary" />
        </template>

        <b-dropdown-item :to="{ name: 'admin-profile' }" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Hesabım</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Çıkış</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import SearchBar from "@core/layouts/components/app-navbar/components/SearchBar.vue";
import AppDropdown from "./AppDropdown.vue";

export default {
  components: {
    // Navbar Components
    DarkToggler,
    SearchBar,
    AppDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout", { sendLogoutReq: true });
    },
  },
};
</script>
