<template>
  <a class="nav-link text-secondary text-darken-2" @click="skin = isDark ? 'light' : 'dark'">
    <feather-icon size="21" :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" />
  </a>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
import { BNavItem } from "bootstrap-vue";

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig();
    if (skin.value === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    }

    const isDark = computed(() => skin.value === "dark");

    return { skin, isDark };
  },
};
</script>
