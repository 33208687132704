<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[{ expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) }, skin === 'semi-dark' ? 'menu-dark' : 'menu-light']"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded mb-75">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed" :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <!-- <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img width="30" heigth="30" :src="appLogoImage" alt="logo" />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li> -->
          <li class="nav-item" style="flex: 1">
            <b-link class="navbar-brand" :class="{ 'justify-content-center': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) }" to="/">
              <b-img v-show="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)" width="150" :src="appLogoImageExpanded" alt="logo" />
              <b-img v-show="isVerticalMenuCollapsed && !isMouseHovered" width="30" :src="appLogoImage" alt="logo" />
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
              <!-- <img class="d-none d-xl-block filter-pin" @click="toggleCollapsed" :src="pinIcon" /> -->
              <feather-icon :icon="collapseTogglerIconFeather" size="24" class="d-none d-xl-block collapse-toggle-icon" @click="toggleCollapsed" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->
    <!-- Shadow -->
    <!-- <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" /> -->

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
      <hr />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    /* const pinIcon = require("./pin-icon.svg"); */
    const pinIcon = computed(() => (collapseTogglerIcon.value === "unpinned" ? require("./pin-icon.svg") : require("./unpin-icon.svg")));

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() => (isVerticalMenuCollapsed.value ? "ChevronsRightIcon" : "ChevronsLeftIcon"));

    // App Name
    const { appName, appLogoImage, appLogoImageExpanded } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoImageExpanded,

      pinIcon,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.filter-pin {
  filter: invert(20%) sepia(88%) saturate(7279%) hue-rotate(358deg) brightness(97%) contrast(109%);
}
</style>
