export default [
  {
    title: "GÜNCEL DURUM",
    route: "dashboard",
    icon: "HomeIcon",
    resource: "dashboard",
  },

  {
    title: "RİSKOMER YÖNETİM",
    mdiIcon: "AccountGroupOutline",
    children: [
      { title: "Çalışanlar", route: "riskomer-employees" },
      { title: "İdari Personel", route: "riskomer-admins" },
      { title: "Eğitimler" },
      { title: "Performans" },
      { title: "İzinler" },
      { title: "Zimmetler" },
      { title: "Fazla Mesai" },
      { title: "Raporlar" },
    ],
  },
  {
    title: "İSG YÖNETİMİ",
    mdiIcon: "AccountMultipleOutline",
    children: [
      {
        title: "Kurumsal Müşteriler",
        route: "companies",
        resource: "work-safety",
      },
      {
        title: "Tüm Ziyaretler",
        route: "visits",
        resource: "work-safety",
      },
      {
        title: "Bireysel Müşteriler",
        route: "individual-customers",
      },
      {
        title: "Raporlar",
      },
    ],
  },

  {
    title: "MÜŞTERİ HİZMETLERİ",
    mdiIcon: "ChatQuestionOutline",
    children: [
      {
        title: "Destek Talepleri",
        route: "support",
      },
      {
        title: "Telefon Görüşmeleri",
        children: [{ title: "Santral", route: "central" }, { title: "Rehber" }],
      },

      {
        title: "Mesaj Gönderimleri",
        children: [{ title: "SMS" }, { title: "E-Posta" }],
      },
    ],
  },
  {
    title: "SATIŞ YÖNETİMİ",
    mdiIcon: "CartOutline",
    children: [
      {
        title: "Web Pazarlama",
        children: [
          {
            title: "Kampanyalar",
            route: "web-actions-campaigns",
          },
          {
            title: "Birim Fiyatlar",
            route: "web-actions-prices",
          },
          {
            title: "Üst Banner",
            route: "web-actions-announces",
          },
          {
            title: "DB Giriş",
            route: "web-actions-option-data",
          },
          {
            title: "Kuponlar",
            route: "web-actions-coupons",
          },
        ],
      },
      {
        title: "Olası Müşteriler",
        route: "central-history",
      },
      {
        title: "Fiyat Teklifleri",
        route: "web-requests",
      },
    ],
  },
  {
    title: "FİNANS YÖNETİMİ",
    mdiIcon: "Finance",
    children: [
      {
        title: "Müşteriler",
        route: "finance-contacts",
        resource: "finance-contacts",
      },
      {
        title: "Tahsilatlar",
        route: "finance-invoice-payments",
        resource: "finance-payments",
      },
      {
        title: "Faturalar",
        route: "finance-invoices",
        resource: "finance-invoices",
      },

      {
        title: "Giderler",
        route: "finance-expenditures",
        resource: "finance-expenditures",
      },
      /*       {
        title: "Aramalar",
        route: "finance-calls",
        resource: "finance-calls",
      }, */
      {
        title: "SMS",
        route: "finance-sms",
        resource: "finance-sms",
      },
      {
        title: "Raporlar",
        route: "finance-reports",
        resource: "finance-reports",
      },
    ],
  },
  {
    title: "SİSTEM YÖNETİMİ",
    icon: "MonitorIcon",
    children: [
      {
        title: "Onaylar",
        route: "confirms",
        pendingConfirms: true,
      },
      {
        title: "Tanımlamalar",
        route: "web-ayarlar",
      },
      {
        title: "Girişler ve Güvenlik",
        route: "customer-logs",
      },
      {
        title: "İSG Döküman & Planlar",
        route: "doc-templates",
        resource: "work-safety",
      },
      {
        title: "Araç Filo Yönetimi",
      },
    ],
  },
  {
    title: "AYARLAR",
    mdiIcon: "Cogs",
    children: [
      {
        title: "Genel Ayarlar",
        route: "settings",
      },
      {
        title: "Yetki Yönetimi",
        route: "role-management",
      },
      {
        title: "Hesabım",
        route: "admin-profile",
        resource: "profile",
      },
      {
        title: "QR Kodları",
      },
      {
        title: "Backend Service",
        route: "dev",
      },
    ],
  },
];
